import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfIXuEjyjCupHVG-qjvOyhdCbkNlkCbyk",
  authDomain: "adobeform-7fcd3.firebaseapp.com",
  projectId: "adobeform-7fcd3",
  storageBucket: "adobeform-7fcd3.appspot.com",
  messagingSenderId: "433434716456",
  appId: "1:433434716456:web:ce2ea4e1085566a11d081d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

//console.log("Firestore instance (db):", db); // Console log the Firestore instance

export { db };